import React from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import { Helmet } from 'react-helmet'
import Button from 'components/button/button'
import { graphql, Link } from 'gatsby'
import Layout from 'components/layout'
import Content from 'components/new/content'
import JobsSearch from 'components/jobs-search'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import JobListingAlertsForm from 'components/forms/job-listing-alerts'
import H2 from 'components/new/typography/h2'
import H3 from 'components/new/typography/h3'
import H4 from 'components/new/typography/h4'
import P from 'components/new/typography/p'
import Ul from 'components/new/ul'
import Spacer from 'components/new/spacer'
import { FaCheckCircle } from '@react-icons/all-files/fa/FaCheckCircle'
import AdditionalResources from 'components/new/additional-resources'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const PartsCareersPage = props => {
  const {
    data: { heroImage },
  } = props
  return (
    <Layout>
      <Helmet>
        <title>Parts Career Path | Hutson Inc</title>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Careers',
                'item': 'https://www.hutsoninc.com/careers/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Parts Career Path',
                'item': 'https://www.hutsoninc.com/careers/service/',
              },
            ],
          })}
        </script>
      </Helmet>

      <Hero
        image={heroImage.childImageSharp.gatsbyImageData}
        title='Parts Career Path'
        overlayOpacity={0.3}
      />

      <Content kind='full'>
        <TopGrid>
          <SearchCol>
            <JobsSearch />
          </SearchCol>
          <MainCol>
            <H2>About our parts department</H2>
            <P>
              Our customers rely on us to find the parts they need. From the parts counter to the
              warehouse, we strive to serve our customers with speed and accuracy.
            </P>
            <P>
              An agriculture or service background isn't required to get started with Hutson's parts
              department. All you need is a willingness to learn and grow. We have several career
              opportunities, including parts sales, warehouse and management roles!
            </P>
            <Spacer size='m' />
            <H3>Hutson's commitment to you</H3>
            <P>
              Hutson wants to support you in furthering your career. We will also invest in your
              continued learning and development through John Deere University and training programs
              offered by STIHL and other shortlines we carry.
            </P>
            <P>Here's what you can expect when joining the Hutson parts department:</P>
            <BenefitsList>
              <li>
                <strong>Continuous Training</strong>: Stay up to date on the latest technology with
                training and certification programs.
              </li>
              <li>
                <strong>Opportunities for Growth</strong>: Climb the ranks in your career as you
                gain experience.
              </li>
              <li>
                <strong>Employee Discounts</strong>: Get special discounts on parts, service and
                equipment at Hutson.
              </li>
              <li>
                <strong>Competitive Benefits Packages</strong>: Get benefits that are actually
                beneficial.
              </li>
              <li>
                <strong>Employee Referral Bonus up to $4,000</strong>: Get rewarded if you refer
                someone that makes a great addition to our team.
              </li>
            </BenefitsList>
            <Spacer size='m' />
            <ButtonContainer>
              <Button as={Link} to='/careers/parts/jobs/' ghost color='green'>
                Browse Parts positions
              </Button>
            </ButtonContainer>
          </MainCol>
        </TopGrid>
      </Content>
      <LightSection>
        <Content kind='full'>
          <H2>Parts career path</H2>
          <P>
            No prior experience? No problem! Get started in an entry level parts sales or warehouse
            position.
          </P>
          <Spacer size='m' />
          <Table>
            <thead>
              <tr>
                <td></td>
                <td>
                  <TableLevelBanner sc-level={1}>Entry</TableLevelBanner>
                  <TableHeaderInner>
                    <H4>Level 1</H4>
                    <TableLevelSubheader>0-3 years of experience</TableLevelSubheader>
                    <Spacer size='xs' />
                    <a href='#level-1'>Learn more</a>
                  </TableHeaderInner>
                </td>
                <td>
                  <TableLevelBanner sc-level={2}>Intermediate</TableLevelBanner>
                  <TableHeaderInner>
                    <H4>Level 2</H4>
                    <TableLevelSubheader>3-6 years of experience</TableLevelSubheader>
                    <Spacer size='xs' />
                    <a href='#level-2'>Learn more</a>
                  </TableHeaderInner>
                </td>
                <td>
                  <TableLevelBanner sc-level={3}>Senior</TableLevelBanner>
                  <TableHeaderInner>
                    <H4>Level 3</H4>
                    <TableLevelSubheader>6+ years of experience</TableLevelSubheader>
                    <Spacer size='xs' />
                    <a href='#level-3'>Learn more</a>
                  </TableHeaderInner>
                </td>
              </tr>
              <tr>
                <td>Classes must be completed within</td>
                <td>
                  <MobileLabel>Level 1</MobileLabel> 3 years
                </td>
                <td>
                  <MobileLabel>Level 2</MobileLabel> 3-6 years
                </td>
                <td>
                  <MobileLabel>Level 3</MobileLabel> 6 years
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Parts Processes &amp; Systems Proficiency**</th>
                <td>
                  <MobileLabel>Level 1</MobileLabel> Novice
                </td>
                <td>
                  <MobileLabel>Level 2</MobileLabel> Advanced
                </td>
                <td>
                  <MobileLabel>Level 3</MobileLabel> Expert
                </td>
              </tr>
              <tr>
                <th>Selling Courses</th>
                <td>
                  <MobileLabel>Level 1</MobileLabel> 6
                </td>
                <td>
                  <MobileLabel>Level 2</MobileLabel> 11*
                </td>
                <td>
                  <MobileLabel>Level 3</MobileLabel> 16*
                </td>
              </tr>
              <tr>
                <th>Common Parts Courses</th>
                <td>
                  <MobileLabel>Level 1</MobileLabel> 3
                </td>
                <td>
                  <MobileLabel>Level 2</MobileLabel> 6*
                </td>
                <td>
                  <MobileLabel>Level 3</MobileLabel> 9-10*
                </td>
              </tr>
              <tr>
                <th>Shortline Training</th>
                <td>
                  <MobileLabel>Level 1</MobileLabel>
                  <Check />
                </td>
                <td>
                  <MobileLabel>Level 2</MobileLabel>
                  <Check />
                </td>
                <td>
                  <MobileLabel>Level 3</MobileLabel>
                  <Check />
                </td>
              </tr>
              <tr>
                <th>Customer Service Skills Course</th>
                <td>
                  <MobileLabel>Level 1</MobileLabel>
                </td>
                <td>
                  <MobileLabel>Level 2</MobileLabel>
                  <Check />
                </td>
                <td>
                  <MobileLabel>Level 3</MobileLabel>
                  <Check />
                </td>
              </tr>
              <tr>
                <th>Selling Skills Web Class</th>
                <td>
                  <MobileLabel>Level 1</MobileLabel>
                </td>
                <td>
                  <MobileLabel>Level 2</MobileLabel>
                  <Check />
                </td>
                <td>
                  <MobileLabel>Level 3</MobileLabel>
                  <Check />
                </td>
              </tr>
              <tr>
                <th>Parts &amp; Service Outside Selling Course</th>
                <td>
                  <MobileLabel>Level 1</MobileLabel>
                </td>
                <td>
                  <MobileLabel>Level 2</MobileLabel>
                  <Check />
                </td>
                <td>
                  <MobileLabel>Level 3</MobileLabel>
                  <Check />
                </td>
              </tr>
              <tr>
                <th>Using the Multi-Use Account to Sell Ag &amp; Turf Parts Course</th>
                <td>
                  <MobileLabel>Level 1</MobileLabel>
                </td>
                <td>
                  <MobileLabel>Level 2</MobileLabel>
                  <Check />
                </td>
                <td>
                  <MobileLabel>Level 3</MobileLabel>
                  <Check />
                </td>
              </tr>
              <tr>
                <th>Customer Segmentation Course</th>
                <td>
                  <MobileLabel>Level 1</MobileLabel>
                </td>
                <td>
                  <MobileLabel>Level 2</MobileLabel>
                  <Check />
                </td>
                <td>
                  <MobileLabel>Level 3</MobileLabel>
                  <Check />
                </td>
              </tr>
            </tbody>
          </Table>
          <Spacer size='s' />
          <TableDisclaimer>
            Ranking is also based on attitude, teamwork, attendance and other factors. * Includes
            courses from previous levels. ** Proficiency determined by Location Manager &amp;
            Regional Manager with Aftermarket VP approval.
          </TableDisclaimer>
          <Spacer size='l' />
          <LevelContainer sc-level={1} id='level-1'>
            <div>
              <LevelHeader>Level 1</LevelHeader>
              <LevelHeaderAppend>0-3 years of experience</LevelHeaderAppend>
            </div>
            <P>
              <strong>Entry level</strong> parts sales and warehouse position with limited or no
              prior experience.
            </P>
            <Ul>
              <li>
                Requires a combination of basic knowledge and manual skill typically acquired
                through training or apprenticeship.
              </li>
              <li>
                Works under close supervision according to explicit instructions and established
                protocols and procedures.
              </li>
            </Ul>
            <Spacer size='m' />
            <H4>Required classes and training</H4>
            <P>The following classes must be completed within 3 years:</P>
            <Checklist
              items={[
                'Selling Engine Oil',
                'Selling Batteries',
                'Selling Filters',
                'Selling Drivelines',
                'Common Parts Needs - Sprayers',
                'Shortline Training',
                'Selling Home & Workshop Products',
                'Common Parts Needs - Planters',
                'Selling Turf Parts',
                'Common Parts Needs - Combines',
              ]}
              level={1}
            />
            <P>
              Will have novice proficiency in the parts process, Parts Advisor, Parts Expert and
              EQUIP. Proficiency determined by Location Manager &amp; Regional Manager with
              Aftermarket VP approval. Ranking is also based on attitude, teamwork, attendance and
              other factors.
            </P>
          </LevelContainer>
          <Spacer size='xxs' />
          <LevelContainer sc-level={2} id='level-2'>
            <div>
              <LevelHeader>Level 2</LevelHeader>
              <LevelHeaderAppend>3-6 years of experience</LevelHeaderAppend>
            </div>
            <P>
              <strong>Intermediate level</strong> parts sales position with basic knowledge and
              prior experience.
            </P>
            <Ul>
              <li>
                Completes straight forward assignments according to instructions and established
                procedures with minimal supervision.
              </li>
              <li>
                Adds knowledge, skill and proficiency in work area, and builds competence in the use
                of computers and technical equipment to complete assigned duties.
              </li>
            </Ul>
            <Spacer size='m' />
            <H4>Required classes and training</H4>
            <P>
              Must complete all Level 1 classes and training. The following classes must be
              completed within 3-6 years:
            </P>
            <Checklist
              items={[
                'Selling Hydraulic Fluids',
                'Selling PowerGard',
                'Selling Grease',
                'Selling Lubricants',
                'Selling the Right Parts at the Right Price',
                'Common Parts Needs - Round Balers',
                'Common Parts Needs - Row Crop Tractors',
                'Common Parts Needs - Gator Utility Vehicles',
              ]}
              level={2}
            />
            <P>
              Will have advanced proficiency in the parts process, Parts Advisor, Parts Expert and
              EQUIP. Proficiency determined by Location Manager &amp; Regional Manager with
              Aftermarket VP approval. Ranking is also based on attitude, teamwork, attendance and
              other factors.
            </P>
          </LevelContainer>
          <Spacer size='xxs' />
          <LevelContainer sc-level={3} id='level-3'>
            <div>
              <LevelHeader>Level 3</LevelHeader>
              <LevelHeaderAppend>6+ years of experience</LevelHeaderAppend>
            </div>
            <P>
              <strong>Expert level</strong> parts salesperson that is experienced, fully competent,
              works independently and receives minimal or no direction.
            </P>
            <Ul>
              <li>
                Identifies opportunities to improve quality or reliability and contributes to
                product, process or parts improvements.
              </li>
              <li>Studies, recommends and implements lean processing daily.</li>
              <li>
                Coaches and provides informal guidance to less-experienced staff, but is not a
                formal manager or supervisor.
              </li>
            </Ul>
            <Spacer size='m' />
            <H4>Required classes and training</H4>
            <P>
              Must complete all Level 1 &amp; 2 classes and training. The following classes must be
              completed within 6 years:
            </P>
            <Checklist
              items={[
                'Selling Hydraulic Hoses',
                'Selling Coolants',
                'Selling Fuel Additives',
                'Selling Chemicals',
                'Selling Roller Chains',
                'Common Parts Needs - Air Seeders',
                'Common Parts Needs - Tillage Equipment',
                'Common Parts Needs - Compact Utility Tractors',
                'Common Parts Needs - Self-Propelled Forage Harvesters (if applicable)',
              ]}
              level={3}
            />
            <P>
              Will have expert proficiency in the parts process, Parts Advisor, Parts Expert and
              EQUIP. Proficiency determined by Location Manager &amp; Regional Manager with
              Aftermarket VP approval. Ranking is also based on attitude, teamwork, attendance and
              other factors.
            </P>
          </LevelContainer>
          <Spacer size='l' />
          <H3>Leadership and advancement opportunities</H3>
          <P>
            More opportunities may become available as you advance in the parts department. If
            you're a team builder and a leader, a Parts Manager role or other leadership position
            may be your next step. You may also decide to transition into a sales or Precision Ag
            role. The choice is yours!
          </P>
        </Content>
      </LightSection>
      <Content kind='full'>
        <H2>Take the next step</H2>
        <P>
          Interested in working for Hutson? We hope you take some time to view our current career
          opportunities and apply! If you have any questions, reach out to our team at{' '}
          <a href='mailto:recruiting@hutsoninc.com'>recruiting@hutsoninc.com</a>.
        </P>
        <Spacer size='m' variable />
        <ButtonContainer>
          <Button as={Link} to='/careers/parts/jobs/' ghost color='green'>
            Browse Parts positions
          </Button>
        </ButtonContainer>
      </Content>
      <JobListingAlertsForm />
      <AdditionalResources
        resources={[
          {
            title: 'Careers',
            links: [
              <Link to='/careers/'>Careers at Hutson</Link>,
              <Link to='/careers/jobs/'>Browse open positions</Link>,
            ],
          },
          {
            title: 'About Us',
            links: [
              <Link to='/about/'>About us</Link>,
              <Link to='/locations/'>Our locations</Link>,
              <Link to='/contact/'>Contact us</Link>,
            ],
          },
          {
            title: 'Disclosures',
            links: [
              <OutboundLink href='https://www.anthem.com/machine-readable-file/search/'>
                Transparency in coverage
              </OutboundLink>,
            ],
          },
        ]}
      />
    </Layout>
  )
}

const Table = styled.table`
  border: none;
  border-collapse: collapse;
  color: ${props => props.theme.color.n900};
  width: 100%;

  td,
  th,
  tr {
    padding: 0;
  }

  @media (max-width: 899px) {
    thead {
      tr {
        :first-child {
          display: none;
        }

        :last-child {
          margin-bottom: ${props => props.theme.size.m};

          td {
            background-color: #fff;
            display: flex;
            border-top: ${props => `1px solid ${props.theme.color.n30}`};
            position: relative;

            :first-child {
              background-color: ${props => props.theme.color.n10};
              font-weight: 600;
              text-align: left;
            }
          }
        }
      }
    }

    tbody {
      tr {
        border: ${props => `1px solid ${props.theme.color.n30}`};

        :not(:last-child) {
          margin-bottom: ${props => props.theme.size.m};
        }

        th {
          background-color: ${props => props.theme.color.n10};
          font-weight: 600;
          text-align: left;
        }

        td {
          background-color: #fff;
          display: flex;
          border-top: ${props => `1px solid ${props.theme.color.n30}`};
          padding: ${props => props.theme.size.s};
          position: relative;
        }
      }
    }

    th,
    td {
      padding: ${props => props.theme.size.s};
    }

    tr,
    th,
    td {
      display: block;
      width: 100%;
    }
  }

  @media (min-width: 900px) {
    background-color: #fff;
    border: ${props => `1px solid ${props.theme.color.n30}`};

    th,
    td {
      border: ${props => `1px solid ${props.theme.color.n30}`};
      padding: ${props => props.theme.size.s};
    }

    tr {
      td {
        :first-child {
          width: 330px;
        }
      }
    }

    thead {
      text-align: center;

      h4 {
        text-align: center;
      }

      tr {
        :first-child {
          td {
            padding: 0;
          }
        }

        :last-child {
          background-color: ${props => props.theme.color.n700};
          color: #fff;
          font-size: 14px;
          font-weight: 600;

          td {
            padding: 8px 12px;

            :first-child {
              padding: 8px 16px;
              text-align: left;
            }
          }
        }
      }
    }

    tbody {
      th {
        font-weight: 400;
        text-align: left;
      }

      td {
        text-align: center;
      }

      tr {
        :nth-child(odd) {
          background-color: ${props => props.theme.color.n10};
        }
      }
    }
  }
`

const TableDisclaimer = styled.p`
  font-style: italic;
  padding: ${props => `0 ${props.theme.size.s}`};
`

const TableLevelBanner = styled.div`
  background-color: ${props => {
    switch (props['sc-level']) {
      case 1:
        return props.theme.color.y50
      case 2:
        return props.theme.color.y200
      default:
      case 3:
        return props.theme.color.y400
    }
  }};
  color: ${props => {
    switch (props['sc-level']) {
      case 3:
        return props.theme.color.y700
      default:
        return props.theme.color.y600
    }
  }};
  font-weight: 600;
  padding: ${props => props.theme.size.xs};
`

const TableHeaderInner = styled.div`
  padding: ${props => `${props.theme.size.s} ${props.theme.size.xxs}`};

  a {
    color: ${props => props.theme.color.g400};
  }
`

const TableLevelSubheader = styled.div`
  color: ${props => props.theme.color.n700};
  font-size: 12px;
  margin-top: ${props => props.theme.size.xxs};
`

const MobileLabel = styled.span`
  flex-grow: 2;

  @media (min-width: 900px) {
    display: none;
  }
`

const Check = () => {
  return <StyledCheck aria-hidden='true' focusable='false' role='presentation' />
}

const StyledCheck = styled(FaCheckCircle)`
  color: ${props => props.theme.color.g400};
  font-size: 21px;
`

const LevelContainer = styled.div`
  background-color: #fff;
  border-left: 8px solid
    ${props => {
      switch (props['sc-level']) {
        case 1:
          return props.theme.color.y50
        case 2:
          return props.theme.color.y200
        default:
        case 3:
          return props.theme.color.y400
      }
    }};
  padding: 24px;
`

const LevelHeader = styled(H3)`
  display: inline-block;
  margin-right: ${props => props.theme.size.xs};
`

const LevelHeaderAppend = styled.span`
  color: ${props => props.theme.color.n700};
  display: inline-block;
  font-size: 14px;
  font-style: italic;
`

const LightSection = styled.div`
  background-color: ${props => props.theme.color.n20};
`

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media (min-width: 900px) {
    height: 450px;
  }
`

const BenefitsList = styled(Ul)`
  @media (min-width: 900px) {
    columns: 2;
    column-gap: ${props => props.theme.size.m};
  }
`

const ButtonContainer = styled.div`
  a {
    display: inline-block;
    text-decoration: none;
  }
`

const TopGrid = styled.div`
  @media (min-width: 900px) {
    ${clearfix}
    direction: rtl;
  }
`

const MainCol = styled.div`
  margin-top: 40px;

  @media (min-width: 900px) {
    ${column('2/3', 0)}
    direction: ltr;
    display: inline-block;
    float: none !important;
    margin-top: 0;
    padding: 25px 30px 0 0;
    vertical-align: top;
  }

  @media (min-width: 1200px) {
    ${column('3/4', 0)}
  }
`

const SearchCol = styled.div`
  @media (min-width: 900px) {
    ${column('1/3', 0)}
    direction: ltr;
    display: inline-block;
    float: none !important;
    vertical-align: top;
  }

  @media (min-width: 1200px) {
    ${column('1/4', 0)}
  }
`

const Checklist = ({ items, level }) => {
  return (
    <StyledChecklist>
      {items.map((item, key) => (
        <li key={`level-${level}-${key}`}>
          <Check /> {item}
        </li>
      ))}
    </StyledChecklist>
  )
}

const StyledChecklist = styled(Ul)`
  list-style: none;
  padding: 0;

  li {
    * {
      vertical-align: middle;
    }
  }

  svg {
    display: inline-block;
    font-size: 16px;
    margin-right: 8px;
  }

  @media (min-width: 600px) {
    columns: 2;
    column-gap: ${props => props.theme.size.m};
  }

  @media (min-width: 900px) {
    columns: 3;
  }
`

export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "careers/parts-hero.jpg" }) {
      ...FullWidthImage
    }
  }
`

export default PartsCareersPage
